<template>
  <v-main class="py-n12">
    <router-view style="height: 100%;" />
  </v-main>
</template>
<script>
  export default {
    name: 'MainView',
    components: {
    },
  }
</script>
<style>
  .v-main__wrap {
    flex: auto !important;
    max-width: 100%;
    position: relative;
  }
</style>